var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "process-progress" },
    [
      _c("div", { staticClass: "resume-upload-result" }, [
        _c("h1", [
          _vm._v(
            "上传" + _vm._s(_vm.isPersonalCreate ? "个人" : "企业") + "简历"
          ),
        ]),
        _c("div", { staticClass: "h2 result-title" }, [
          _vm.excelFileName
            ? _c("span", { staticClass: "excel-file-name" }, [
                _vm._v("【" + _vm._s(_vm.excelFileName) + "】"),
              ])
            : _vm._e(),
          _c("span", { staticClass: "result-count" }, [
            _vm._v("\n                上传"),
            _c("span", { staticClass: "color-orange" }, [
              _vm._v(
                _vm._s(
                  _vm.isExcelUpload
                    ? _vm.excelUploadTotal
                    : _vm.tableData.length
                )
              ),
            ]),
            _vm._v("份，解析中"),
            _c("span", { staticClass: "color-orange" }, [
              _vm._v(_vm._s(_vm.count.doingCount)),
            ]),
            _vm._v("份，成功"),
            _c("span", { staticClass: "color-orange" }, [
              _vm._v(_vm._s(_vm.count.succeedCount)),
            ]),
            _vm._v("份，失败"),
            _c("span", { staticClass: "color-orange" }, [
              _vm._v(_vm._s(_vm.count.failedCount)),
            ]),
            _vm._v("份\n            "),
          ]),
          _vm._m(0),
        ]),
        _c(
          "div",
          { staticClass: "table-content" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: { data: _vm.tableData },
              },
              [
                !_vm.isExcelUpload
                  ? _c("el-table-column", {
                      attrs: {
                        prop: "fileName",
                        label: "文件名",
                        width: "310",
                        "show-overflow-tooltip": true,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", { staticClass: "file-name" }, [
                                  _c("span", { staticClass: "name" }, [
                                    _vm._v(_vm._s(scope.row._fileName)),
                                  ]),
                                  _c("span", { staticClass: "type" }, [
                                    _vm._v(_vm._s(scope.row._fileType)),
                                  ]),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1796008901
                      ),
                    })
                  : _vm._e(),
                _c("el-table-column", {
                  attrs: {
                    "class-name": "real-name",
                    prop: "realName",
                    label: "姓名",
                    width: _vm.isExcelUpload ? "200" : "122",
                    "show-overflow-tooltip": true,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          !scope.row.realName
                            ? _c("span", [_vm._v("—")])
                            : _c(
                                "span",
                                {
                                  class: scope.row.resumeId
                                    ? "real-name-link"
                                    : "",
                                  on: {
                                    click: function ($event) {
                                      scope.row.resumeId
                                        ? _vm.goToCandidate(scope.row.resumeId)
                                        : ""
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(scope.row.realName) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "result",
                    label: "添加候选人结果",
                    width: _vm.isExcelUpload ? "400" : "332",
                    "show-overflow-tooltip": true,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.status == 2 || scope.row.status == 4
                            ? _c(
                                "span",
                                { staticClass: "upload-status" },
                                [
                                  _c("font-icon", {
                                    staticClass: "lbd-icon__upload_status",
                                    attrs: { href: "#icon-resume_ic_success" },
                                  }),
                                  _c("span", [_vm._v("成功")]),
                                  scope.row.status == 4
                                    ? _c("span", [
                                        _vm._v("：个人库存在，已为你自动更新"),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              )
                            : scope.row.status == 3 || scope.row.status == 5
                            ? _c(
                                "span",
                                { staticClass: "upload-status" },
                                [
                                  _c("font-icon", {
                                    staticClass: "lbd-icon__upload_status",
                                    attrs: {
                                      href: "#icon-resume_ic_default-copy",
                                    },
                                  }),
                                  _c("span", { staticClass: "color-red" }, [
                                    _vm._v("失败"),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      "：" + _vm._s(scope.row.failedReaosn)
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : scope.row.status == 1
                            ? _c("span", { staticClass: "upload-status" }, [
                                _c("span", { staticClass: "icon-analyze" }),
                                _c("span", { staticClass: "color-blue" }, [
                                  _vm._v("解析中..."),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "操作",
                    width: _vm.isExcelUpload ? "" : "219",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.isCanGrab
                            ? _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleFirm(
                                        scope.row,
                                        scope.$index
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "text-operate-btn" },
                                    [_vm._v("导入企业库")]
                                  ),
                                  _vm._v(
                                    "（抢占归属）\n                        "
                                  ),
                                ]
                              )
                            : scope.row.status == 3 && scope.row.failedType == 1
                            ? _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticClass: "text-operate-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleFill(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("去补充")]
                                ),
                              ])
                            : (scope.row.status == 3 &&
                                scope.row.failedType == 2) ||
                              scope.row.status == 5
                            ? _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticClass: "text-operate-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleCompare(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("去对比")]
                                ),
                              ])
                            : _c("div", [_c("span", [_vm._v("—")])]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { prop: "uploadTime", label: "上传时间", width: 300 },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isExcelLoading,
                expression: "isExcelLoading",
              },
            ],
            staticClass: "layer-analyze",
          },
          [
            _c("div", { staticClass: "screen" }),
            _c("div", { staticClass: "layer-body" }, [
              _c("div", { staticClass: "icon-analyze" }),
              _c("span", { staticClass: "excelupload-text" }, [
                _vm._v("解析上传中，请勿关闭本页面…"),
              ]),
              _c("span", { staticClass: "excelupload-count" }, [
                _vm._v("\n                    上传"),
                _c("span", { staticClass: "color-orange" }, [
                  _vm._v(_vm._s(_vm.excelUploadTotal)),
                ]),
                _vm._v("份，解析中"),
                _c("span", { staticClass: "color-orange" }, [
                  _vm._v(_vm._s(_vm.count.doingCount)),
                ]),
                _vm._v("份，成功"),
                _c("span", { staticClass: "color-orange" }, [
                  _vm._v(_vm._s(_vm.count.succeedCount)),
                ]),
                _vm._v("份，失败"),
                _c("span", { staticClass: "color-orange" }, [
                  _vm._v(_vm._s(_vm.count.failedCount)),
                ]),
                _vm._v("份\n                "),
              ]),
            ]),
          ]
        ),
      ]),
      _c("import-result-dialog", {
        ref: "importResultDialog",
        attrs: { isTextContext: true },
        on: { "import-then-compare": _vm.goToCompare },
      }),
      _c("div", { staticClass: "lbd-sign" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "result-tips" }, [
      _c("em", [_vm._v("Tips：")]),
      _vm._v("\n                上传完成后可在\n                "),
      _c(
        "a",
        {
          staticClass: "text-operate-btn",
          attrs: { href: "/Headhunting/#/talentPool", target: "_blank" },
        },
        [_vm._v("Talentpool")]
      ),
      _vm._v("\n                进行查看\n            "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }